
.self-center a, .self-center:link {
    color: #5B6B93;
    text-decoration: none;
}
@media only screen and (min-width: 550px ) and (max-width: 995px) {
    .self-center {
        display: flex;
        align-items: flex-end;
        position: absolute;
        right: 2%;
        bottom: 0;
    }
    .self-center-app {
        display: none;
    }

    .self-center ul {
        list-style-type: none;
        margin-top: 0;
    }

    .self-center li {
        display: inline-block;
        margin-left: 8px;
        font-family: PingFangSC-Regular, serif;
        font-size: 16px;
        color: #5B6B93;
        text-align: center;
        cursor: pointer;
    }
    .login-icon {
        background-color: #f18f60;
        padding: 5px;
        border-radius: 5px;
        color: white !important;
    }
    .logout-icon {
        background-color: gray;
        padding: 5px;
        border-radius: 5px;
        color: white !important;
    }
}
@media only screen and (min-width: 996px) {
    .login-icon {
        background-color: #f18f60;
        padding: 5px;
        border-radius: 5px;
        color: white !important;
    }
    .logout-icon {
        background-color: gray;
        padding: 5px;
        border-radius: 5px;
        color: white !important;
    }
    .self-center {
        display: flex;
        align-items: flex-end;
        position: absolute;
        right: 17%;
        bottom: 0;
    }

    .self-center ul {
        list-style-type: none;
        margin-top: 0;
    }

    .self-center li {
        display: inline-block;
        margin-left: 8px;
        font-family: PingFangSC-Regular, serif;
        font-size: 16px;
        color: #5B6B93;
        text-align: center;
        cursor: pointer;
    }
    .self-center-app {
        display: none;
    }
}
@media only screen and (max-width: 549px) {
    .self-center {
        width: 100%;
        height: 10%;
        position: fixed;
        bottom: 0;
    }
    .self-center-browser {
        display: none !important;
    }
    .self-center ul {
        list-style-type: none;
        margin-top: 0;
        padding-left: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .self-center li {
        font-family: PingFangSC-Medium, serif;
        font-size: 20px;
        color: #5B6B93;
        background-image: radial-gradient(50% 203%, rgba(212,229,239) 0%, rgba(163,187,219) 100%);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        text-align: center;
        cursor: pointer;
        height: 100%;
        width: 33.3%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}
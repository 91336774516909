.browser-myorders {
    padding: 30px;
}
.browser-myorders td, .browser-myorders th{
    min-width: 60px;
}
.hide-order-list {
    display: none;
}
.hide-detail {
    display: none;
}
.reminder {
    color: #5B6B93;
}
.reminder {
    text-align: left;
}
@media only screen and (max-width: 549px) {
    .detail-nav {
        position: absolute;
        top: -17px;
        left: -26px;
        width: 120px;
    }
    .browser-myorders {
        display: none;
    }

    .order-detail .label-btn {
        width: 40px !important;
    }
    .order-detail {
        margin-left: 10%;
        margin-top: 8%;
    }
    .app-myorders {
        padding-bottom: 65px;
    }
    .app-order {
        width: 80%;
        background-image: linear-gradient(180deg, #FFFFFF 0%, #E9E9E9 100%);
        border-radius: 2px;
        margin: 20px 30px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        font-size: 12px;
        color: #5B6B93;
    }
    .app-order .order-label {
        font-size: 12px;
        color: #5B6B93;
        font-weight: 600;
        margin-right: 20px;
        width: 50px;
        display: inline-block;
        text-align: left;
    }
    .app-order a {
        font-size: 14px;
        color: #5B6B93;
    }
    .order-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 50px;
        align-items: center;

    }
    .order-type {
        font-size: 16px;
        color: #5B6B93;
        text-align: center;
        font-weight: 500;
    }
    .order-extra {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .order-price {
        font-size: 14px;
        color: #5B6B93;
        text-align: center;
    }
    .order-paid {
        font-size: 12px;
        color: #5B6B93;
        text-align: center;
        margin-left: 30px;
    }

}
@media only screen and (min-width: 549px) {
    .app-myorders {
        display: none;
    }
}

.order-wrapper .hide {
    display: none;
}
.control-group, .label, .upload-sec,  .radio-info, .remind-info, .order-btn {
    display: inline-block;
}
.err {
    display: inline-block;
}
.order-btn, .order-tab, .radio-info{
    cursor: pointer;
}
.order-detail {
    position: relative;
}
input {
    background-image: linear-gradient(134deg, #FFFFFF 0%, #EFEFEF 100%, #E9E9E9 100%);
    border-radius: 5px;
    border-color: #efecec;
}
@media only screen and (min-width: 550px ) and (max-width: 995px) {
    .detail-nav {
        position: absolute;
        top: -30px;
        left: -52px;
        width: 200px;
    }
    .order-detail {
        padding-bottom: 80px;
        margin-left: 10%;
        margin-top: 7%;
    }
    .order-detail .label-btn {
        width: 90px !important;
    }
    .order-wrapper .control-group {
        width: auto;
        margin-top: 20px;
    }
    .order-wrapper .label {
        width: 180px;
        text-align: right;
        font-size: 20px;
        color: #5B6B93;
        font-weight: 500;
        letter-spacing: 0.26px;
        line-height: 38px;
        vertical-align: top;
    }
    .myorders .order-wrapper .order-btn {
        background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
        border-radius: 5px;
        width: 150px;
        text-align: center;
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: 0.26px;
        margin-top: 20px;
        line-height: 38px;
        margin-left: 4%;
        padding: 5px;
        cursor: pointer;
    }
    .control-group .radio {
        width: 18px;
        height: 18px;
    }
    .control-group .radio-info {
        font-size: 20px;
        color: #5B6B93;
        letter-spacing: 0.26px;
        line-height: 38px;
        width: 60%;
    }
    .control-group .remind-info {
        font-size: 18px;
        color: #ED6D46;
        line-height: 38px;
        font-weight: 600;
        width: 60%;
        margin-left: 15px;
    }
    .remind-info p {
        margin-top: 0;
        margin-bottom: 0;
    }
    .confirm-info .label, .finish-info .label{
        color: #5B6B93;
    }
}

@media only screen and (min-width: 996px) {
    .detail-nav {
        position: absolute;
        top: -60px;
        left: -128px;
    }
    .order-detail {
        margin-left: 28%;
        margin-top: 7%;
    }

    .order-wrapper .control-group {
        width: auto;
        margin-top: 20px;
    }
    .order-detail .order-wrapper .label {
        width: 180px;
        text-align: right;
        font-size: 20px;
        color: #5B6B93;
        font-weight: 500;
        letter-spacing: 0.26px;
        line-height: 38px;
        vertical-align: top;
    }
    .myorders .order-wrapper .order-btn {
        background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
        border-radius: 5px;
        border-radius: 5px;
        width: 180px;
        text-align: center;
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: 0.26px;
        margin-top: 20px;
        line-height: 38px;
        margin-left: 10%;
        padding: 5px;
    }
    .control-group .radio {
        width: 18px;
        height: 18px;
    }
    .control-group .radio-info {
        font-size: 20px;
        color: #5B6B93;
        letter-spacing: 0.26px;
        line-height: 38px;
        width: 60%;
    }
    .control-group .remind-info {
        font-size: 20px;
        color: #ED6D46;
        letter-spacing: 0.26px;
        line-height: 38px;
        font-weight: 600;
        width: 60%;
        margin-left: 20px;
    }
    .remind-info p {
        margin-top: 0;
        margin-bottom: 0;
    }
    .confirm-info .label, .finish-info .label{
        color: #5B6B93;
    }
}
@media only screen and (max-width: 549px) {


    .order-wrapper .control-group {
        width: 100%;
        margin-top: 10px;
    }
    .order-wrapper .label {
        width: 100px;
        text-align: right;
        font-size: 12px;
        letter-spacing: 0.15px;
        line-height: 24px;
        vertical-align: top;
        font-weight: 550;

    }

    .image-gallery img {
        width: 100%;
        height: 100%;
    }
    .order-info .upload-image {
        display: flex;
        flex-direction: column;
        width: 48%;
        cursor: pointer;
    }
    .order-detail .order-wrapper .order-btn {
        background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
        border-radius: 5px;
        width: 60px;
        margin-bottom: 40px;
        text-align: center;
        font-size: 10px;
        color: #FFFFFF;
        letter-spacing: 0.26px;
        margin-left: 10%;
        padding: 7px;
        font-weight: 600;
    }
    .control-group .radio {
        width: 13px;
        height: 13px;
    }
    .control-group .radio-info {
        font-size: 12px;
        color: #5B6B93;
        letter-spacing: 0.26px;
        line-height: 30px;
        width: 70%;
    }



    .control-group .remind-info {
        font-size: 12px;
        color: #ED6D46;
        line-height: 24px;
        font-weight: 550;
        width: 50%;
        margin-left: 20px;
    }
    .remind-info p {
        margin-top: 0;
        margin-bottom: 0;
    }

}





.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.app-bg, .browser-bg {
    width: 100vw;
    height: 100vh;
}
@media only screen and (min-width: 550px) {
    .app-bg {
         display: none;
    }
}
@media only screen and (max-width: 549px) {
    .browser-bg {
        display: none;
    }
}
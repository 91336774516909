@media only screen and (min-width: 550px ) and (max-width: 995px) {
    .logo {
        width: 119px;
        height: 43px;
    }
}
@media only screen and (min-width: 996px) {
    .logo {
        width: 141px;
        height: 58px;
    }
}
@media only screen and (max-width: 549px) {
    .logo {
        width: 72px;
        height: 30px;
        position: absolute;
        top: 18px;
        left: 5%;
    }
}
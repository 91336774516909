.order-wrapper .hide {
	    display: none;
    }
    .control-group, .label, .upload-sec, .dropdown, .radio-info, .remind-info, .order-btn {
	        display: inline-block;
	}
	.required-glowing {
		    border: 1px red solid !important;
		        box-shadow: 0 0 10px red;
		}
		.err {
			    display: inline-block;
		    }
		    .order-btn, .order-tab, .radio-info{
			        cursor: pointer;
			}
			.hide-tab {
				    display: none !important;
			    }
			    .nav-wrapper {
				        background-image: radial-gradient(circle at 50% 0%, rgba(212,229,239,0.50) 0%, rgba(163,187,219,0.50) 50%);
					    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
				    }
				    .so-head {
					        font-family: FZLTZHK--GBK1-0;
						    font-size: 26px;
						        color: #5B6B93;
							    letter-spacing: 0.33px;
							        text-align: center;
							}
							.pay-overlay {
								    position: fixed;
								        top: 0%;
									    left: 0%;
									        width: 100%;
										    height: 100%;
										        background-color: black;
											    z-index: 1001;
											        -moz-opacity: 0.95;
												    opacity: 0.7;
												        filter: alpha(opacity = 95);
												}
												.pay-popup {
													    width: 400px;
													        z-index: 1002;
														    left: 50%;
														        position:absolute;
															    top:50%;
															        transform: translate(-50%,-50%);
															}
															.plus-order.active {
																    background-image: linear-gradient(135deg, #87C0CA 0%, #108B96 100%) !important;
																        border-radius: 5px !important;
																	    color: #FFFFFF !important;
																	        text-align: center !important;
																	}
																	.plus-order.inactive {
																		    background-image: linear-gradient(135deg, #ECECEC 0%, #CDCDCD 100%) !important;
																		        border-radius: 5px !important;
																			    color: #108B96 !important;
																			        text-align: center !important;
																			}
																			.order-tab {
																				    position: relative;
																			    }
																			    .radio-info label {
																				        margin-left: 5px;
																					    cursor: pointer;
																				    }
																				    input {
																					        background-image: linear-gradient(134deg, #FFFFFF 0%, #EFEFEF 100%, #E9E9E9 100%);
																						    border-radius: 5px;
																						        border-color: #efecec;
																						}

																						.nav-myorder.nav-myorder.nav-myorder {
																							    background-image: linear-gradient(180deg, #FAFAFA 0%, #BBBBBB 100%) !important;
																							        color: #5B6B93 !important;
																							}
																							@media only screen and (min-width: 550px ) and (max-width: 995px) {
																								    .confirm-detail {
																									            max-width: 356px;
																										        }
																											    .confirm-info.confirm-info-plus {
																												            margin-left: 15%;
																													        }
																														    .plus-click {
																															            position:  absolute;
																																            top: 35px;
																																	        }
																																		    .nav-plus {
																																			            position: absolute;
																																				            left: 4%;
																																					        }
																																						    .nav-plus .arrow {
																																							            font-size: 16px;
																																								            color: #5B6B93;
																																									            font-weight: 600;
																																										            padding: 0 !important;
																																											        }
																																												    .nav-myorder {
																																													            background-image: linear-gradient(180deg, #FAFAFA 0%, #BBBBBB 100%) !important;
																																														            color: #5B6B93 !important;
																																															        }
																																																    .order-tab ul {
																																																	            list-style-type: none;
																																																		            display: flex;
																																																			            padding-top: 50px;
																																																				            justify-content: flex-end;
																																																					            margin-right: 2%;
																																																						            flex-wrap: wrap;
																																																							        }
																																																								    .label-btn {
																																																									            width: 130px !important;
																																																										        }

																																																											    .order-tab .active {
																																																												            background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
																																																													            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
																																																														            border-radius: 5px;
																																																															            font-family: PingFangSC-Semibold;
																																																																            font-size: 21px;
																																																																	            color: #FFFFFF;
																																																																		            letter-spacing: 0.27px;
																																																																			            text-align: center;
																																																																				        }

																																																																					    .order-tab .inactive {
																																																																						            font-size: 21px;
																																																																							            color: #FFFFFF;
																																																																								            letter-spacing: 0.27px;
																																																																									            text-align: center;
																																																																										        }

																																																																											    .order-tab li {
																																																																												            display: inline-block;
																																																																													            font-size: 16px;
																																																																														            color: #5B6B93;
																																																																															            text-align: center;
																																																																																            font-weight: 550;
																																																																																	            padding: 2px 5px;
																																																																																		        }
																																																																																			    .order-wrapper input, .dropdown {
																																																																																				            height: 40px;
																																																																																					            width: 270px;
																																																																																						            margin-left: 20px !important;
																																																																																							        }

																																																																																								    .dropdown .ant-select-selector, .dropdown .ant-select-selection-item, .dropdown .ant-select-selection-placeholder{
																																																																																									            height: 40px !important;
																																																																																										            line-height: 40px !important;
																																																																																											        }
																																																																																												    #second-last-step {
																																																																																													            margin-left: 200px;
																																																																																														        }
																																																																																															    .order-wrapper .control-group {
																																																																																																            width: auto;
																																																																																																	            margin-top: 20px;
																																																																																																		        }
																																																																																																			    .control-group.confirm-detail {
																																																																																																				            width: auto;
																																																																																																					        }
																																																																																																						    .order-info,  .confirm-info, .finish-info, .submit-photo {
																																																																																																							            margin-top: 30px;
																																																																																																								            padding-bottom: 50px;
																																																																																																									            display: flex;
																																																																																																										            flex-direction: column;
																																																																																																											            align-items: center;
																																																																																																												        }
																																																																																																													    .order-wrapper .label {
																																																																																																														            width: 180px;
																																																																																																															            text-align: right;
																																																																																																																            font-size: 20px;
																																																																																																																	            color: #5B6B93;
																																																																																																																		            font-weight: 550;
																																																																																																																			            letter-spacing: 0.26px;
																																																																																																																				            line-height: 38px;
																																																																																																																					            vertical-align: top;
																																																																																																																						        }
																																																																																																																							    .order-info .upload-sec {
																																																																																																																								            width: 270px;
																																																																																																																									            position: relative;
																																																																																																																										            margin-left: 20px;
																																																																																																																											        }
																																																																																																																												    .order-info .image-gallery {
																																																																																																																													            position: absolute;
																																																																																																																														            right: 0;
																																																																																																																															            top: 0;
																																																																																																																																            width: 49%;
																																																																																																																																	            height: 100%;
																																																																																																																																		        }
																																																																																																																																			    .image-gallery img {
																																																																																																																																				            width: 100%;
																																																																																																																																					            height: 100%;
																																																																																																																																						        }
																																																																																																																																							    .order-info .upload-image {
																																																																																																																																								            display: flex;
																																																																																																																																									            flex-direction: column;
																																																																																																																																										            width: 50%;
																																																																																																																																											            cursor: pointer;
																																																																																																																																												        }
																																																																																																																																													    #first-submit {
																																																																																																																																														            margin-left: 200px;
																																																																																																																																															            width: 270px;
																																																																																																																																																        }
																																																																																																																																																	    .order-wrapper .order-btn {
																																																																																																																																																		            background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
																																																																																																																																																			            border-radius: 5px;
																																																																																																																																																				            width: 150px;
																																																																																																																																																					            text-align: center;
																																																																																																																																																						            font-size: 20px;
																																																																																																																																																							            color: #FFFFFF;
																																																																																																																																																								            letter-spacing: 0.26px;
																																																																																																																																																									            margin-top: 20px;
																																																																																																																																																										            line-height: 38px;
																																																																																																																																																											            margin-left: 4%;
																																																																																																																																																												            padding: 5px;
																																																																																																																																																													            cursor: pointer;
																																																																																																																																																														        }
																																																																																																																																																															    .control-group .radio {
																																																																																																																																																																            width: 18px;
																																																																																																																																																																	            height: 18px;
																																																																																																																																																																		        }
																																																																																																																																																																			    .control-group .radio-info {
																																																																																																																																																																				            font-size: 20px;
																																																																																																																																																																					            color: #5B6B93;
																																																																																																																																																																						            letter-spacing: 0.26px;
																																																																																																																																																																							            line-height: 38px;
																																																																																																																																																																								            width: 60%;
																																																																																																																																																																									        }
																																																																																																																																																																										    .control-group .reminder {
																																																																																																																																																																											            font-size: 20px;
																																																																																																																																																																												            color: #5B6B93;
																																																																																																																																																																													            text-align: left;
																																																																																																																																																																														            line-height: 38px;
																																																																																																																																																																															            font-weight: 550;
																																																																																																																																																																																            width: auto;
																																																																																																																																																																																	        }
																																																																																																																																																																																		    .control-group .remind-info {
																																																																																																																																																																																			            font-size: 18px;
																																																																																																																																																																																				            color: #ED6D46;
																																																																																																																																																																																					            line-height: 38px;
																																																																																																																																																																																						            font-weight: 550;
																																																																																																																																																																																							            width: 60%;
																																																																																																																																																																																								            margin-left: 15px;
																																																																																																																																																																																									        }
																																																																																																																																																																																										    .remind-info p {
																																																																																																																																																																																											            margin-top: 0;
																																																																																																																																																																																												            margin-bottom: 0;
																																																																																																																																																																																													        }
																																																																																																																																																																																														    .confirm-info .label, .finish-info .label{
																																																																																																																																																																																															            color: #5B6B93;
																																																																																																																																																																																																        }

																																																																																																																																																																																																}

																																																																																																																																																																																																@media only screen and (min-width: 996px) {

																																																																																																																																																																																																	    .confirm-detail {
																																																																																																																																																																																																		            max-width: 356px;
																																																																																																																																																																																																			        }
																																																																																																																																																																																																				    .confirm-info.confirm-info-plus .control-group .label {
																																																																																																																																																																																																					             width: 250px;
																																																																																																																																																																																																						         }
																																																																																																																																																																																																							     .nav-plus {
																																																																																																																																																																																																								             position: absolute;
																																																																																																																																																																																																									             left: 16%;
																																																																																																																																																																																																										         }
																																																																																																																																																																																																											     .nav-plus .arrow {
																																																																																																																																																																																																												             font-size: 16px;
																																																																																																																																																																																																													             color: #5B6B93;
																																																																																																																																																																																																														             font-weight: 600;
																																																																																																																																																																																																															             padding: 0 !important;
																																																																																																																																																																																																																         }
																																																																																																																																																																																																																	     .order-tab ul {
																																																																																																																																																																																																																		             list-style-type: none;
																																																																																																																																																																																																																			             display: flex;
																																																																																																																																																																																																																				             padding-top: 50px;
																																																																																																																																																																																																																					             margin-top: 0;
																																																																																																																																																																																																																						             justify-content: flex-end;
																																																																																																																																																																																																																							             margin-right: 17%;
																																																																																																																																																																																																																								         }

																																																																																																																																																																																																																									     .order-tab .active {
																																																																																																																																																																																																																										             background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
																																																																																																																																																																																																																											             box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
																																																																																																																																																																																																																												             border-radius: 5px;
																																																																																																																																																																																																																													             font-family: PingFangSC-Semibold;
																																																																																																																																																																																																																														             font-size: 21px;
																																																																																																																																																																																																																															             color: #FFFFFF;
																																																																																																																																																																																																																																             letter-spacing: 0.27px;
																																																																																																																																																																																																																																	             text-align: center;
																																																																																																																																																																																																																																		         }

																																																																																																																																																																																																																																			     .order-tab .inactive {
																																																																																																																																																																																																																																				             font-size: 21px;
																																																																																																																																																																																																																																					             color: #FFFFFF;
																																																																																																																																																																																																																																						             letter-spacing: 0.27px;
																																																																																																																																																																																																																																							             text-align: center;
																																																																																																																																																																																																																																								         }

																																																																																																																																																																																																																																									     .order-tab li {
																																																																																																																																																																																																																																										             display: inline-block;
																																																																																																																																																																																																																																											             font-size: 21px;
																																																																																																																																																																																																																																												             color: #5B6B93;
																																																																																																																																																																																																																																													             letter-spacing: 0.27px;
																																																																																																																																																																																																																																														             text-align: center;
																																																																																																																																																																																																																																															             font-weight: 550;
																																																																																																																																																																																																																																																             padding: 5px 10px;
																																																																																																																																																																																																																																																	         }
																																																																																																																																																																																																																																																		     #first-submit {
																																																																																																																																																																																																																																																			             margin-left: 200px;
																																																																																																																																																																																																																																																				             width: 350px;
																																																																																																																																																																																																																																																					         }
																																																																																																																																																																																																																																																						     .order-wrapper input,  .dropdown {
																																																																																																																																																																																																																																																							             height: 40px;
																																																																																																																																																																																																																																																								             width: 350px;
																																																																																																																																																																																																																																																									             margin-left: 20px !important;
																																																																																																																																																																																																																																																										         }
																																																																																																																																																																																																																																																											     .dropdown .ant-select-selector, .dropdown .ant-select-selection-item, .dropdown .ant-select-selection-placeholder{
																																																																																																																																																																																																																																																												             height: 40px !important;
																																																																																																																																																																																																																																																													             line-height: 40px !important;
																																																																																																																																																																																																																																																														         }
																																																																																																																																																																																																																																																															     .order-wrapper .control-group {
																																																																																																																																																																																																																																																																             width: auto;
																																																																																																																																																																																																																																																																	             margin-top: 20px;
																																																																																																																																																																																																																																																																		         }
																																																																																																																																																																																																																																																																			     .order-info,  .confirm-info, .finish-info, .submit-photo{
																																																																																																																																																																																																																																																																				             margin-top: 30px;
																																																																																																																																																																																																																																																																					             padding-bottom: 50px;
																																																																																																																																																																																																																																																																						             display: flex;
																																																																																																																																																																																																																																																																							             flex-direction: column;
																																																																																																																																																																																																																																																																								             align-items: center;
																																																																																																																																																																																																																																																																									         }
																																																																																																																																																																																																																																																																										     .order-wrapper .label {
																																																																																																																																																																																																																																																																											             width: 180px;
																																																																																																																																																																																																																																																																												             text-align: right;
																																																																																																																																																																																																																																																																													             font-size: 20px;
																																																																																																																																																																																																																																																																														             color: #5B6B93;
																																																																																																																																																																																																																																																																															             font-weight: 550;
																																																																																																																																																																																																																																																																																             letter-spacing: 0.26px;
																																																																																																																																																																																																																																																																																	             line-height: 38px;
																																																																																																																																																																																																																																																																																		             vertical-align: top;
																																																																																																																																																																																																																																																																																			         }
																																																																																																																																																																																																																																																																																				     .order-info .upload-sec {
																																																																																																																																																																																																																																																																																					             width: 350px;
																																																																																																																																																																																																																																																																																						             position: relative;
																																																																																																																																																																																																																																																																																							             margin-left: 20px;
																																																																																																																																																																																																																																																																																								         }
																																																																																																																																																																																																																																																																																									     .order-info .image-gallery {
																																																																																																																																																																																																																																																																																										             position: absolute;
																																																																																																																																																																																																																																																																																											             right: 0;
																																																																																																																																																																																																																																																																																												             top: 0;
																																																																																																																																																																																																																																																																																													             width: 49%;
																																																																																																																																																																																																																																																																																														             height: 100%;
																																																																																																																																																																																																																																																																																															         }
																																																																																																																																																																																																																																																																																																     .image-gallery img {
																																																																																																																																																																																																																																																																																																	             width: 100%;
																																																																																																																																																																																																																																																																																																		             height: 100%;
																																																																																																																																																																																																																																																																																																			         }
																																																																																																																																																																																																																																																																																																				     .order-info .upload-image {
																																																																																																																																																																																																																																																																																																					             display: flex;
																																																																																																																																																																																																																																																																																																						             flex-direction: column;
																																																																																																																																																																																																																																																																																																							             width: 50%;
																																																																																																																																																																																																																																																																																																								             cursor: pointer;
																																																																																																																																																																																																																																																																																																									         }
																																																																																																																																																																																																																																																																																																										     #second-last-step {
																																																																																																																																																																																																																																																																																																											             margin-left: 200px;
																																																																																																																																																																																																																																																																																																												         }
																																																																																																																																																																																																																																																																																																													     .order-wrapper .order-btn {
																																																																																																																																																																																																																																																																																																														             background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
																																																																																																																																																																																																																																																																																																															             border-radius: 5px;
																																																																																																																																																																																																																																																																																																																             border-radius: 5px;
																																																																																																																																																																																																																																																																																																																	             width: 180px;
																																																																																																																																																																																																																																																																																																																		             text-align: center;
																																																																																																																																																																																																																																																																																																																			             font-size: 20px;
																																																																																																																																																																																																																																																																																																																				             color: #FFFFFF;
																																																																																																																																																																																																																																																																																																																					             letter-spacing: 0.26px;
																																																																																																																																																																																																																																																																																																																						             margin-top: 20px;
																																																																																																																																																																																																																																																																																																																							             line-height: 38px;
																																																																																																																																																																																																																																																																																																																								             margin-left: 10%;
																																																																																																																																																																																																																																																																																																																									             padding: 5px;
																																																																																																																																																																																																																																																																																																																										         }
																																																																																																																																																																																																																																																																																																																											     .control-group .radio {
																																																																																																																																																																																																																																																																																																																												             width: 18px;
																																																																																																																																																																																																																																																																																																																													             height: 18px;
																																																																																																																																																																																																																																																																																																																														         }
																																																																																																																																																																																																																																																																																																																															     .control-group .radio-info {
																																																																																																																																																																																																																																																																																																																																             font-size: 20px;
																																																																																																																																																																																																																																																																																																																																	             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																		             letter-spacing: 0.26px;
																																																																																																																																																																																																																																																																																																																																			             line-height: 38px;
																																																																																																																																																																																																																																																																																																																																				             width: 60%;
																																																																																																																																																																																																																																																																																																																																					         }
																																																																																																																																																																																																																																																																																																																																						     .control-group .reminder {
																																																																																																																																																																																																																																																																																																																																							             font-size: 20px;
																																																																																																																																																																																																																																																																																																																																								             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																									             letter-spacing: 0.26px;
																																																																																																																																																																																																																																																																																																																																										             text-align: left;
																																																																																																																																																																																																																																																																																																																																											             line-height: 38px;
																																																																																																																																																																																																																																																																																																																																												             font-weight: 550;
																																																																																																																																																																																																																																																																																																																																													             width: auto;
																																																																																																																																																																																																																																																																																																																																														         }
																																																																																																																																																																																																																																																																																																																																															     .control-group .remind-info {
																																																																																																																																																																																																																																																																																																																																																             font-size: 20px;
																																																																																																																																																																																																																																																																																																																																																	             color: #ED6D46;
																																																																																																																																																																																																																																																																																																																																																		             letter-spacing: 0.26px;
																																																																																																																																																																																																																																																																																																																																																			             line-height: 38px;
																																																																																																																																																																																																																																																																																																																																																				             font-weight: 550;
																																																																																																																																																																																																																																																																																																																																																					             width: 60%;
																																																																																																																																																																																																																																																																																																																																																						             margin-left: 20px;
																																																																																																																																																																																																																																																																																																																																																							         }
																																																																																																																																																																																																																																																																																																																																																								     .remind-info p {
																																																																																																																																																																																																																																																																																																																																																									             margin-top: 0;
																																																																																																																																																																																																																																																																																																																																																										             margin-bottom: 0;
																																																																																																																																																																																																																																																																																																																																																											         }
																																																																																																																																																																																																																																																																																																																																																												     .confirm-info .label, .finish-info .label{
																																																																																																																																																																																																																																																																																																																																																													             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																																														         }
																																																																																																																																																																																																																																																																																																																																																														 }
																																																																																																																																																																																																																																																																																																																																																														 @media only screen and (max-width: 549px) {
																																																																																																																																																																																																																																																																																																																																																															     .finish-info .control-group {
																																																																																																																																																																																																																																																																																																																																																																             width: auto !important;
																																																																																																																																																																																																																																																																																																																																																																	         }
																																																																																																																																																																																																																																																																																																																																																																		     .confirm-info.confirm-info-plus {
																																																																																																																																																																																																																																																																																																																																																																			             margin-left: 7%;
																																																																																																																																																																																																																																																																																																																																																																				         }
																																																																																																																																																																																																																																																																																																																																																																					     .plus-click {
																																																																																																																																																																																																																																																																																																																																																																						             position:  absolute;
																																																																																																																																																																																																																																																																																																																																																																							             top: 24px;
																																																																																																																																																																																																																																																																																																																																																																								         }
																																																																																																																																																																																																																																																																																																																																																																									     .nav-plus {
																																																																																																																																																																																																																																																																																																																																																																										             width: 100%;
																																																																																																																																																																																																																																																																																																																																																																											             background-image: radial-gradient(circle at 50% 0%, rgba(212,229,239,0.50) 0%, rgba(163,187,219,0.50) 50%);
																																																																																																																																																																																																																																																																																																																																																																												             box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
																																																																																																																																																																																																																																																																																																																																																																													             font-size: 18px;
																																																																																																																																																																																																																																																																																																																																																																														             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																																																															             letter-spacing: 0.23px;
																																																																																																																																																																																																																																																																																																																																																																																             text-align: center;
																																																																																																																																																																																																																																																																																																																																																																																	         }
																																																																																																																																																																																																																																																																																																																																																																																		     .confirm-detail {
																																																																																																																																																																																																																																																																																																																																																																																			             max-width: 356px;
																																																																																																																																																																																																																																																																																																																																																																																				         }
																																																																																																																																																																																																																																																																																																																																																																																					     .plus-head.plus-head {
																																																																																																																																																																																																																																																																																																																																																																																						             width: 100% !important;
																																																																																																																																																																																																																																																																																																																																																																																							             height: 100%;
																																																																																																																																																																																																																																																																																																																																																																																								             background-image: none !important;
																																																																																																																																																																																																																																																																																																																																																																																									             background-color: #108B96 !important;
																																																																																																																																																																																																																																																																																																																																																																																										             border-radius: 0 !important;
																																																																																																																																																																																																																																																																																																																																																																																											         }
																																																																																																																																																																																																																																																																																																																																																																																												     .so-head.so-head {
																																																																																																																																																																																																																																																																																																																																																																																													             width: 100%;
																																																																																																																																																																																																																																																																																																																																																																																														             background-image: radial-gradient(circle at 50% 0%, rgba(212,229,239,0.50) 0%, rgba(160, 185, 217, 0.3) 50%);
																																																																																																																																																																																																																																																																																																																																																																																															             box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
																																																																																																																																																																																																																																																																																																																																																																																																             margin-top: 0px;
																																																																																																																																																																																																																																																																																																																																																																																																	             font-size: 18px;
																																																																																																																																																																																																																																																																																																																																																																																																		             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																																																																																			             letter-spacing: 0.23px;
																																																																																																																																																																																																																																																																																																																																																																																																				             text-align: center;
																																																																																																																																																																																																																																																																																																																																																																																																					             height: 50px;
																																																																																																																																																																																																																																																																																																																																																																																																						         }
																																																																																																																																																																																																																																																																																																																																																																																																							     .nav-plus .arrow {
																																																																																																																																																																																																																																																																																																																																																																																																								             display: none;
																																																																																																																																																																																																																																																																																																																																																																																																									         }
																																																																																																																																																																																																																																																																																																																																																																																																										     .nav-myorder {
																																																																																																																																																																																																																																																																																																																																																																																																											             display: none !important;
																																																																																																																																																																																																																																																																																																																																																																																																												         }
																																																																																																																																																																																																																																																																																																																																																																																																													     .order-tab {
																																																																																																																																																																																																																																																																																																																																																																																																														             width: 100%;
																																																																																																																																																																																																																																																																																																																																																																																																															             height: 64px;
																																																																																																																																																																																																																																																																																																																																																																																																																         }
																																																																																																																																																																																																																																																																																																																																																																																																																	     .order-tab > ul > li {
																																																																																																																																																																																																																																																																																																																																																																																																																		             height: 25px ;
																																																																																																																																																																																																																																																																																																																																																																																																																			         }
																																																																																																																																																																																																																																																																																																																																																																																																																				     .order-tab ul {
																																																																																																																																																																																																																																																																																																																																																																																																																					             list-style-type: none;
																																																																																																																																																																																																																																																																																																																																																																																																																						             margin-top: 0;
																																																																																																																																																																																																																																																																																																																																																																																																																							             padding-left: 0;
																																																																																																																																																																																																																																																																																																																																																																																																																								             height: 100%;
																																																																																																																																																																																																																																																																																																																																																																																																																									             display: flex;
																																																																																																																																																																																																																																																																																																																																																																																																																										             align-items: center;
																																																																																																																																																																																																																																																																																																																																																																																																																											             flex-wrap: wrap;
																																																																																																																																																																																																																																																																																																																																																																																																																												         }

																																																																																																																																																																																																																																																																																																																																																																																																																													     .order-tab .active {
																																																																																																																																																																																																																																																																																																																																																																																																																														             background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
																																																																																																																																																																																																																																																																																																																																																																																																																															             box-shadow: 0 1px 2px 0 rgba(0,0,0,0.50);
																																																																																																																																																																																																																																																																																																																																																																																																																																             border-radius: 2px;
																																																																																																																																																																																																																																																																																																																																																																																																																																	             font-size: 12px;
																																																																																																																																																																																																																																																																																																																																																																																																																																		             color: #FFFFFF;
																																																																																																																																																																																																																																																																																																																																																																																																																																			             letter-spacing: 0.15px;
																																																																																																																																																																																																																																																																																																																																																																																																																																				             text-align: center;
																																																																																																																																																																																																																																																																																																																																																																																																																																					         }

																																																																																																																																																																																																																																																																																																																																																																																																																																						     .order-tab .inactive {
																																																																																																																																																																																																																																																																																																																																																																																																																																							             font-size: 12px;
																																																																																																																																																																																																																																																																																																																																																																																																																																								             color: #FFFFFF;
																																																																																																																																																																																																																																																																																																																																																																																																																																									             letter-spacing: 0.15px;
																																																																																																																																																																																																																																																																																																																																																																																																																																										             text-align: center;
																																																																																																																																																																																																																																																																																																																																																																																																																																											         }

																																																																																																																																																																																																																																																																																																																																																																																																																																												     .nav-wrapper {
																																																																																																																																																																																																																																																																																																																																																																																																																																													             display: flex;
																																																																																																																																																																																																																																																																																																																																																																																																																																														             margin-top: 25px;
																																																																																																																																																																																																																																																																																																																																																																																																																																															             margin-left: 6%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																             width: 88%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																	             height: 25px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																		         }


																																																																																																																																																																																																																																																																																																																																																																																																																																																			     .order-tab li {
																																																																																																																																																																																																																																																																																																																																																																																																																																																				             font-size: 12px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																					             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																																																																																																																																						             letter-spacing: 0.15px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																							             font-weight: 550;
																																																																																																																																																																																																																																																																																																																																																																																																																																																								             text-align: center;
																																																																																																																																																																																																																																																																																																																																																																																																																																																									             cursor: pointer;
																																																																																																																																																																																																																																																																																																																																																																																																																																																										             height: 100%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																											             width: 25%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																												             display: flex;
																																																																																																																																																																																																																																																																																																																																																																																																																																																													             flex-direction: row;
																																																																																																																																																																																																																																																																																																																																																																																																																																																														             align-items: center;
																																																																																																																																																																																																																																																																																																																																																																																																																																																															             justify-content: center;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																	     .order-tab li.plus-order {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																		             width: 50%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																			         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																				     .order-wrapper input, .dropdown{
																																																																																																																																																																																																																																																																																																																																																																																																																																																																					             height: 30px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																						             width: 50%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																							             margin-left: 10px !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																								         }

																																																																																																																																																																																																																																																																																																																																																																																																																																																																									     .dropdown .ant-select-selector, .dropdown .ant-select-selection-item, .dropdown .ant-select-selection-placeholder{
																																																																																																																																																																																																																																																																																																																																																																																																																																																																										             height: 30px !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																											             line-height: 30px !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																												             color: #5B6B93 !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																													             font-size: 12px !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																														         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																															     .ant-select-item.ant-select-item-option {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																             color: #5B6B93 !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																	             font-size: 12px !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																			     .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				             height: 30px !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																					         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																						     .order-wrapper .control-group {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																							             width: auto;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																								             margin-top: 12px !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									             color: #5B6B93 !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																										             font-size: 12px !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																											         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																												     .control-group.confirm-detail {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																													             width: auto !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																														         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																															     .order-info,  .confirm-info, .finish-info, .submit-photo {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																             padding-bottom: 70px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																	             display: flex;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		             flex-direction: column;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																			             align-items: center;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				             margin-top: 50px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																					         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																						     .confirm-info {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																							             margin-left: 8%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																								         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									     .order-wrapper .label {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																										             width: 130px !important;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																											             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																												             text-align: right;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																													             font-size: 12px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																														             letter-spacing: 0.15px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																															             line-height: 24px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																             vertical-align: top;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																	             font-weight: 550;

																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																			     .order-info .upload-sec {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				             width: 60%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																					             position: relative;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																						             margin-left: 10px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																							         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																								     .order-info .image-gallery {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									             position: absolute;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																										             right: 0;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																											             top: 0;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																												             width: 50%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																													             height: 100%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																														         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																															     .image-gallery img {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																             width: 100%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																	             height: 100%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																			     .order-info .upload-image {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				             display: flex;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																					             flex-direction: column;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																						             width: 48%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																							             cursor: pointer;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																								         }

																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									     #first-submit {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																										             margin-left: 135px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																											             width: 50%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																												         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																													     #second-last-step {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																														             margin-left: 110px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																															         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																     .order-wrapper .order-btn {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																	             background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		             border-radius: 5px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																			             width: 90px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				             margin-bottom: 40px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																					             text-align: center;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																						             font-size: 10px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																							             color: #FFFFFF;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																								             letter-spacing: 0.26px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									             margin-left: 10%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																										             padding: 7px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																											             font-weight: 550;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																												         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																													     .control-group .radio {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																														             width: 13px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																															             height: 13px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																	     .control-group .radio-info {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		             font-size: 12px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																			             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				             letter-spacing: 0.26px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																					             line-height: 30px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																						             width: 70%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																							         }

																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																								     .control-group .reminder {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									             font-size: 12px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																										             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																											             letter-spacing: 0.26px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																												             text-align: left;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																													             line-height: 24px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																														             font-weight: 550;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																															             width: auto;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																         }


																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																	     .control-group .remind-info {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		             font-size: 12px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																			             color: #ED6D46;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																				             line-height: 24px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																					             font-weight: 550;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																						             width: 50%;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																							             margin-left: 20px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																								         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									     .remind-info p {
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																										             margin-top: 0;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																											             margin-bottom: 0;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																												         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																													     .confirm-info .label, .finish-info .label{
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																														             color: #5B6B93;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																															         }
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																															 }



body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.beian {
    position: relative;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}
@media only screen and (max-width: 550px ) {
    .beian {
      display: none;
    }
}
.qrModal .ant-modal-content {
    width: 436px;
    background-image: linear-gradient(134deg, #FFFFFF 0%, #ECECEC 59%, #E5E5E5 100%);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    border-radius: 15px;
}
.qrModal ol {
    padding-right: 43px;
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #5B6B93;
    letter-spacing: 0.21px;
}
.qrModal .qrContainer {
    box-shadow: 0 7px 10px -7px rgb(0 0 0 / 50%) inset;
    border-radius: 13px;
    height: 324px;
    width: 324px;
}
.qrContainer img {
    width: 99%;
    height: 100%;
    border-radius: 13px;
    /* display: none; */
    padding: 2px;
}
.qrModal .hint {
    margin-top: 10px
}
@media only screen and (min-width: 550px) {
    .slog {
        display: flex;
        align-items: center;
        position: relative;
        height: 372px;
    }
    .slog::after {
        content: "";
        opacity: 0.71;
        background-image: radial-gradient(50% 203%, rgba(212,229,239,0.50) 0%, rgba(163,187,219,0.50) 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    .slog .content {
        margin-left: 17%;
        margin-bottom: 50px;
    }

    .slog h1 {
        font-size: 24px;
        color: #f18f60;
    }

    .slog p {
        font-size: 20px;
        color: #5B6B93;
        line-height: 38px;
        margin-bottom: 0;
        margin-top: 0;
    }


    .tabs .item-wrapper {
        display: flex;
        justify-content: space-between;
        margin-left: 17%;
        margin-right: 17%;
        flex-wrap: wrap;

    }

    .tabs .tab-item-browser {
        font-size: 25px;
        color: #5B6B93;
        text-align: center;
        margin-top: 80px;
        cursor: pointer;
    }
    .tab-item-app {
        display: none;
    }
}
@media only screen and (max-width: 549px) {
    .slog {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 20%;
    }
    .slog::after {
        content: "";
        opacity: 0.71;
        background-image: radial-gradient(50% 203%, rgba(212,229,239,0.50) 0%, rgba(163,187,219,0.50) 100%);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    .slog .content {
        margin-left: 5%;
        margin-bottom: 20px;
    }

    .slog h1 {
        display: none;
    }

    .slog p {
        max-width:400px;
        font-size: 10px;
        color: #f18f60;
        letter-spacing: 0.13px;
        font-weight: 550;
        text-align: center;
        margin-bottom: 0;
        margin-top: 0;
    }
    .tabs {
        height: 60%;
    }
    .item-wrapper {
        height: 100%;
    }
    .tab-item-app {
        height: 25%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
    }
    .tab-item-app span {
        font-size: 25px;
        color: #FFFFFF;
        letter-spacing: 0.32px;
        text-align: center;
    }
    .tab-item-app img {
        width: 68px;
        height: 44px;
    }
    .tab-item-browser {
        display: none;
    }
}
@media only screen and (min-width: 550px ) and (max-width: 995px) {
    .tab-item-browser {
        flex: 50%;
    }
    .item-wrapper {
        margin-bottom: 80px;
    }
}
.upload-info {
	    display: flex;
	        flex-direction: row;
		    justify-content: center;
	    }

	    .ant-modal-footer {
		        display: flex;
			    justify-content: space-around;
			        padding-bottom: 30px;
			}
			.upload-btns {
				    margin-top: 20px;
				        cursor: pointer;
				}
				.cbtn:hover{
					    background: #ED6D46;
					        color: white;
					}
					.cbtn:focus {
						    background: #ED6D46;
						        color: white;
						}
						.cbtn {
							    background: #ED6D46;
							        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
								    color: white;
								        width: 96px;
									    font-size: 18px;
									        height: 41px;
										    border-radius: 5px;
									    }
									    .upload-btns img {
										        margin-left: 20px;
										}
										.upload-nav {
											    cursor: pointer;
										    }
										    .outline {
											        position: absolute;

											}
											@media only screen and (min-width: 550px ) and (max-width: 995px) {
												    .upload-nav {
													            margin-top: 30px;
														            margin-left: 5%;
															            width: 225px;
																            margin-bottom: 20px;

																	        }
																		    .outline {
																			            width: 480px;
																				            height: 640px;
																					            object-fit: cover;
																						        }
																							    .upload-demo {
																								            width: 356px;
																									            height: auto;
																										        }
																											    .upload-hint {
																												            font-size: 20px;
																													            color: #ED6D46;
																														            display: flex;
																															            margin-top: 50px;
																																            font-weight: 500;
																																	            margin-left: 100px;
																																		            margin-right: 100px;
																																			        }
																																				    .count-down-btn {
																																					            background-image: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
																																						            border-radius: 5px;
																																							            width: 200px;
																																								            margin-bottom: 40px;
																																									            text-align: center;
																																										            font-size: 18px;
																																											            color: #FFFFFF;
																																												            letter-spacing: 0.26px;
																																													            margin-left: 10%;
																																														            padding: 7px;
																																															            font-weight: 550;
																																																        }
																																																}
																																																@media only screen and (min-width: 995px) {
																																																	    .count-down-btn {
																																																		            background-image: linear-gradient(135deg, #ECECEC 0%, #CDCDCD 100%);
																																																			            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
																																																				            border-radius: 5px;
																																																					            width: 200px;
																																																						            text-align: center;
																																																							            font-size: 18px;
																																																								            color: #FFFFFF;
																																																									            letter-spacing: 0.26px;
																																																										            margin-left: 10%;
																																																											            padding: 7px;
																																																												            font-weight: 550;
																																																													        }
																																																														    .upload-info {
																																																															            width: 717px;
																																																																            height: 427px;
																																																																	        }
																																																																		    .upload-nav {
																																																																			            margin-top: 40px;
																																																																				            margin-left: 17%;
																																																																					            margin-bottom: 10px;
																																																																						        }
																																																																							    .upload-demo {
																																																																								            width: 356px;
																																																																									            height: auto;

																																																																										        }
																																																																											    .outline {
																																																																												            width: 480px;
																																																																													            height: 640px;
																																																																														            object-fit: cover;
																																																																															        }
																																																																																    .upload-hint {
																																																																																	            font-size: 16px;
																																																																																		            color: #ED6D46;
																																																																																			            margin-top: 50px;
																																																																																				            font-weight: 500;
																																																																																					            margin-left: 100px;
																																																																																						            margin-bottom: 20px;
																																																																																							        }
																																																																																								    .upload-hint p {
																																																																																									            margin-top: 0;
																																																																																										            margin-bottom: 0;
																																																																																											        }
																																																																																												    .upload-hint h1 {
																																																																																													            font-size: 20px;
																																																																																														            color: #5B6B93;
																																																																																															        }
																																																																																																    .upload-hint hr {
																																																																																																	            margin-top: 20px;
																																																																																																		            margin-bottom: 20px;
																																																																																																			        }
																																																																																																				    .upload-btns {
																																																																																																					            margin-left: 70px;
																																																																																																						            display: flex;
																																																																																																							        }
																																																																																																								    .upload-description {
																																																																																																									            display: flex;
																																																																																																										            flex-direction: column;
																																																																																																											            justify-content: space-between;
																																																																																																												        }
																																																																																																													    .upload-hint span {
																																																																																																														            display: block;
																																																																																																															        }

																																																																																																															}

																																																																																																															@media only screen and (max-width: 550px ) {
																																																																																																																    .upload-demo {
																																																																																																																	            width: 356px;
																																																																																																																		            height: auto;
																																																																																																																			        }
																																																																																																																				    .upload-hint span {
																																																																																																																					            display: inline;
																																																																																																																						        }

																																																																																																																							    .upload-btns img {
																																																																																																																								            width: 98px;
																																																																																																																									            height: 28px;
																																																																																																																										        }
																																																																																																																											    .upload-info {
																																																																																																																												            flex-direction: column;
																																																																																																																													        }
																																																																																																																														    .upload-hint h1{
																																																																																																																															            border-bottom: 1px solid #5B6B93;
																																																																																																																																            width: 96%;
																																																																																																																																	        }
																																																																																																																																		    .upload-info > img {
																																																																																																																																			            margin: auto;
																																																																																																																																				        }
																																																																																																																																					    .upload-demo {
																																																																																																																																						            width: 225px;
																																																																																																																																							            height: auto;
																																																																																																																																								        }
																																																																																																																																									    .upload-hint h1 {
																																																																																																																																										            font-family: FZLTZHK--GBK1-0;
																																																																																																																																											            font-size: 10px;
																																																																																																																																												            color: #5B6B93;
																																																																																																																																													            letter-spacing: 0.13px;
																																																																																																																																														            line-height: 38px;
																																																																																																																																															        }
																																																																																																																																																    .photo-upload-container {
																																																																																																																																																	            width: 100%;
																																																																																																																																																		        }
																																																																																																																																																			    .count-down-btn {
																																																																																																																																																				            background-image: linear-gradient(135deg, #ECECEC 0%, #CDCDCD 100%);
																																																																																																																																																					            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
																																																																																																																																																						            border-radius: 2px;
																																																																																																																																																							            font-family: FZLTTHK--GBK1-0;
																																																																																																																																																								            font-size: 12px;
																																																																																																																																																									            color: #FFFFFF;
																																																																																																																																																										            letter-spacing: 0.15px;
																																																																																																																																																											            text-align: center;
																																																																																																																																																												            width: 148px;
																																																																																																																																																													            line-height: 12px;
																																																																																																																																																														            height: 26px;
																																																																																																																																																															            margin-left: 10%;
																																																																																																																																																																            padding: 7px;
																																																																																																																																																																	            font-weight: 550;
																																																																																																																																																																		        }
																																																																																																																																																																			    .upload-hint {
																																																																																																																																																																				            font-size: 12px;
																																																																																																																																																																					            color: #ED6D46;
																																																																																																																																																																						            display: flex;
																																																																																																																																																																							            margin-top: 10px;
																																																																																																																																																																								            font-weight: 500;
																																																																																																																																																																									            flex-direction: column;
																																																																																																																																																																										            align-items: flex-start;
																																																																																																																																																																											            margin-left: 6%;
																																																																																																																																																																												            width: 90%;
																																																																																																																																																																													        }
																																																																																																																																																																														    .outline {
																																																																																																																																																																															            width: 340px;
																																																																																																																																																																																            height: 450px;
																																																																																																																																																																																	            object-fit: cover;
																																																																																																																																																																																		        }
																																																																																																																																																																																			    .upload-btns {
																																																																																																																																																																																				            margin-top: 20px;
																																																																																																																																																																																					            display: flex;
																																																																																																																																																																																						            justify-content: center;
																																																																																																																																																																																							        }

																																																																																																																																																																																								    .upload-nav {
																																																																																																																																																																																									            margin-top: 15px;
																																																																																																																																																																																										            margin-left: 5%;
																																																																																																																																																																																											            margin-bottom: 10px;
																																																																																																																																																																																												            width: 120px;
																																																																																																																																																																																													        }

																																																																																																																																																																																													}
																																																																																																																																																																																													.disabled-count {
																																																																																																																																																																																														    background: gray !important;
																																																																																																																																																																																														        color: white !important;
																																																																																																																																																																																															    cursor: not-allowed;
																																																																																																																																																																																														    }


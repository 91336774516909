
#root {
	    min-height: 100%;
    }
    @media only screen and (min-width: 550px ) and (max-width: 995px) {
	        .span {
			        background-image: radial-gradient(circle at 50% 0%, rgba(212,229,239,0.50) 0%, rgba(163,187,219,0.50) 50%);
				        padding-left: 5%;
					        display: flex;
						        align-items: center;
							        min-height: 112px;
								        padding-top: 41px;
									        box-sizing: border-box;
										        position: relative;
											        background-color: white;
												    }
											    }
											    @media only screen and (min-width: 996px) {
												        .span {
														        background-image: radial-gradient(circle at 50% 0%, rgba(212,229,239,0.50) 0%, rgba(163,187,219,0.50) 50%);
															        padding-left: 17%;
																        display: flex;
																	        align-items: center;
																		        min-height: 112px;
																			        padding-top: 41px;
																				        box-sizing: border-box;
																					        position: relative;
																						        background-color: white;
																							    }
																						    }
																						    @media only screen and (max-width: 549px) {
																							        .app {
																									        height: 100vh;
																										    }

																									    }
																									    .hide {
																										        display: none;
																										}
																										.show {
																											    display: block;
																										    }



.login-modal-container {
    background-image: linear-gradient(134deg, #FFFFFF 0%, #ECECEC 59%, #E5E5E5 100%);
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.50);
    border-radius: 12px;
    font-family: FZLTXHK--GBK1-0, serif;
    font-size: 14px;
    color: #5B6B93;
    letter-spacing: 0.18px;
    line-height: 14px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
}

.login-modal-container .control-group input {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(134deg, #FFFFFF 0%, #EFEFEF 100%, #E9E9E9 100%);
    box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.50);
    border-radius: 5px;
    font-size: 24px;
    padding-left: 10px;
    box-sizing: border-box;
}
.login-cap {
    font-family: PingFangSC-Medium, serif;
    font-size: 25px;
    color: #5B6B93;
}
.login-modal-container input::placeholder {
    opacity: 0.6;
    font-family: FZLTXHK--GBK1-0, serif;
    font-size: 20px;
    color: #5B6B93;
    padding-left: 5px;
}
.login-btn {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    border-radius: 5px;
    background: linear-gradient(135deg, #F18F60 0%, #ED6D46 100%);
    border-color: #F18F60;
    font-family: FZLTTHK--GBK1-0, serif;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    margin-left: 10px;
    cursor: pointer;


}
.err {
    color: red;
    margin-bottom: 0;
    margin-top: 0;
    height: 10px !important;
    font-weight: 600;
}

.err::before {
    display: inline;
    content: "⚠ ";
}

.smscode-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.smscode, .sendcode {
    margin-top: 0!important;
}
.smscode {
    width: 45% !important;
}
.disabled-sendcode {
    background: gray !important;
    color: white !important;
}
.sendcode {
    width: 45% !important;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    background: #F5B087;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    border-radius: 5px;
    opacity: 0.6;
    font-family: FZLTXHK--GBK1-0, serif;
    font-size: 20px;
    color: white;
    text-align: center;
    line-height: 50px;
}
.sendcode:focus {
    background: #F18F60;
}

@media only screen and (min-width: 550px ) and (max-width: 995px) {
    .login-modal-container {
        height: 350px;
        width: 442px;
    }
    .login-modal-container .control-group {
        width: 290px;
        height: 51px;
        margin-top: 25px;
    }
}
@media only screen and (min-width:996px ) {
    .login-modal-container{
        height: 414px;
        width: 590px;
    }
    .login-modal-container .control-group {
        width: 65%;
        height: 51px;
        margin-top: 35px;
    }
}
@media only screen and (max-width: 549px) {
    .login-modal-container{
        height: 208px;
        width: 296px;
    }
    .login-modal-container .control-group {
        width: 241px;
        height: 28px;
        margin-top: 10px;
    }
    .login-cap {
        font-size: 15px;
    }
    .login-btn {
        font-size: 14px;
        line-height: 14px;
    }
    .login-modal-container .control-group input,
    .login-modal-container input::placeholder,
    .sendcode {
        font-size: 14px;
    }
    .sendcode {
        line-height: 30px;
    }
}
@media only screen and (min-width: 550px) {
    .mini-slog {
        content: "";
        height: 55px;
        width: 100%;
        background-image: radial-gradient(50% 0%, rgba(212,229,239,0.50) 50%, rgba(163,187,219,0.50) 100%);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}
@media only screen and (max-width: 549px) {
    .mini-slog {
        content: "";
        opacity: 0.71;
        background-image: radial-gradient(50% 203%, rgba(212,229,239,0.50) 0%, rgba(163,187,219,0.50) 100%);
        height: 10px;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}
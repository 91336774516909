.th {
    margin-left: 20px;
}
@media only screen and (min-width: 550px ) and (max-width: 995px) {
    .app-logo-txt {
        display: none;
    }
}
@media only screen and (min-width:996px )  {
    .app-logo-txt {
        display: none;
    }

}
@media only screen and (max-width: 549px) {

    .browser-logo-txt {
      display: none;
    }
    .span:before {
        content: "";
        opacity: 0.71;
        background-image: radial-gradient(50% 203%, rgba(212,229,239,0.50) 0%, rgba(163,187,219,0.50) 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    .span svg {
        display: none;
    }
    .th {
        display: none;
    }
}